.card {
    width: 250px;
    height: 230px;
    perspective: 1000px;
  }
  
  .card-inner {
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: transform 0.5s;
  }
  
  .flipped {
    transform: rotateY(180deg);
  }
  
  .card-front,
  .card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    border-radius: 16px; 
    background: linear-gradient(226deg, #7A4092 0%, #144391 49.74%, #7CCCE2 100%); 
  }
  
  .card-back {
    transform: rotateY(180deg);
  }
  