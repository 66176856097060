.gradient-background-button {
  font-size: min(max(13px, 1.055vw), 16px);
  color: #FFFFFF;
  font-family: 'Montserrat';
  white-space: nowrap;
  cursor: pointer;
  font-weight: 600;
  width: fit-content;
  height: 'fit-content';
  background-image: linear-gradient(to bottom left, #7A4092, #144391, #83CDDD, #7A4092);
  padding: 5px 20px 5px 20px;
  border: none;
  border-radius: 20px;
  opacity: 1;
  animation-name: none;
  -o-transition: background-position .4s ease-in-out;
  -webkit-transition: background-position .4s ease-in-out;
  transition: background-position .4s ease-in-out;
  background-size: 100% 200%;
}

.gradient-background-button:hover {
  background-position: 0 100%;
}
