body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  min-width: 100vw;
  max-width: 100vw;
  max-height: 100vw;
  height: 100vh;
  width: 100vw;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.hover-opacity {
  opacity: 1;
}

.hover-opacity:hover {
  transition: opacity 0.3s ease-in-out;
  opacity: 0.8;
}

.service-item {
  cursor: pointer;
  width: 100px;
  height: 100px;
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(to bottom left, #7A4092, #144391, #83CDDD);
  border-radius: 100px;
  background-color: white;
  transition: background-image 1s ease-in-out;
}

.service-item:hover {
  background-image: none;
  background-color: white;
}

.animation-container {
  position: relative;
  width: 100vw;
  height: 300px;
  overflow: hidden;
  background-color: #132443;
}

.moving-div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  opacity: 1;
  height: 300px;
  background-color: #132443;
}

@keyframes slideToLeft {
  0% {
    opacity: 0; /* À droite de la vue */
  }
  100% {
    opacity: 1; /* Déplace complètement à gauche de la vue */
  }
}